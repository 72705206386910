<template>
  <div class="wrapper">
    <edit-temp :headers="headers" :upload-url="uploadUrl" :show-name="true"
               @saveImage="httpRequest"/>
  </div>
</template>

<script>
import editTemp from './EditTemp.vue'

export default {
  components: {editTemp},
  props: {},
  data() {
    return {
      uploadUrl: "admin/upload/uploadImage",
      headers: {
        adClientType: 'pc_console',
        Authentication: this.$store.state.account.token,
      }
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    // 自定义上传图片
    httpRequest(data) {
      const {file, fabricJson, name, classify} = data
      const fd = new FormData()// FormData 对象
      fd.append('file', file)// 文件对象
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          adClientType: 'pc_console',
          Authentication: this.$store.state.account.token,
        }
      }
      this.$upload("upload/uploadImage", fd, config).then(res => { // 上传生成的图片
        this.saveTemplate(JSON.stringify(fabricJson), name, res.data, classify)
      })
    },
    // 保存模板
    saveTemplate(content, modelName, thumbPic, classify) {
      this.$postJson("diy/save", {
        content,
        modelName,
        thumbPic,
        classify
      }).then(res => {
        this.$message.success('保存成功！')
        this.$router.push({path: '/diy/template'})
      })
    }
  }
}
</script>
<style lang="less" scoped>
.wrapper {
}
</style>
